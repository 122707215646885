import type { LoaderFunctionArgs } from "@remix-run/node";
import { json } from "@remix-run/node";
import { headersAuthorization } from "#app/utils/auth.server.ts";
import { api as base } from "#app/utils/http.server.ts";
import {
	ClientLoaderFunctionArgs,
	useFetcher,
	useLocation,
} from "@remix-run/react";

export const loader = async ({ request }: LoaderFunctionArgs) => {
	const headers = await headersAuthorization(request);
	const api = base.extend({ headers });

	const data = await api.get("finance/pay/history").json<Finance>();
	const _data = await api.get("finance/notification").json<Finance>();
	data.notification = _data;

	return json(data);
};

import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "#app/components/ui/select";
import { formatTimeAndDate } from "#app/lib/converter.ts";

function formatCurrency(amount: number) {
	if (!amount) return 0;
	const formattedAmount = new Intl.NumberFormat("id-ID", {
		style: "currency",
		currency: "IDR",
		minimumFractionDigits: 2,
	}).format(amount);

	return formattedAmount;
}

function getStatusLabel(status: string) {
	switch (status) {
		case "SETTLEMENT":
			return "Sudah Dibayar";
		case "CANCEL":
		case "EXPIRE":
		case "EXPIRED":
		case "ERROR":
			return "Transaksi Dibatalkan";
		case "awaiting-approval":
			return "Menunggu Disetujui";
		case "PENDING":
			return "Menunggu Pembayaran";
		case "NEW":
		case "BARU":
			return "Belum Dibayar";
		default:
			return "Status tidak diketahui";
	}
}

import {
	styleDashboard,
	useViewport,
} from "#app/components/client/viewport.client";
import { TitleWithSearch } from "#app/components/title-menu.tsx";
import { Link } from "@remix-run/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Datum, Finance, StatusCustom } from "./finance.server";
export const Payment = ({ isNotif }: { isNotif?: boolean }) => {
	const location = useLocation();
	const fetcher = useFetcher<typeof loader>({ key: location.key });
	const notifFinance = fetcher.data?.data;
	const [filterNotifFinance, setFilterNotifFinance] = useState<StatusCustom[]>(
		[],
	);
	const [valueFilterNotif, setValueFilterNotif] =
		useState<StatusCustom>("Semua");
	const [dataNotifFinance, setDataNotifFinance] = useState<Datum[]>([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [searchResults, setSearchResults] = useState<Datum[]>(dataNotifFinance);

	const handleSearch = () => {
		const filteredMessages = dataNotifFinance.filter(
			(message) =>
				message?.invoiceItems[0]?.name
					.toLowerCase()
					.includes(searchTerm.toLowerCase()) ||
				message?.totalAmount?.toString().includes(searchTerm.toLowerCase()),
		);
		setSearchResults(filteredMessages);
	};

	useEffect(() => {
		if (!fetcher.data?.data) {
			fetcher.submit(
				{ page: 1 },
				{ method: "get", action: "/resources/finance" },
			);
		}
	}, []);

	useEffect(() => {
		handleSearch();
	}, [searchTerm]);

	useEffect(() => {
		setSearchResults(dataNotifFinance);
	}, [dataNotifFinance]);

	useEffect(() => {
		const filteredData = notifFinance?.filter((d) => {
			const status = d.status;
			const isCancel =
				status === "CANCEL" ||
				status === "EXPIRE" ||
				status === "EXPIRED" ||
				status === "ERROR";
			const isSuccess = status === "SETTLEMENT";
			const isPending = status === "PENDING";
			const isWaitApprove = status === "awaiting-approval";
			const isWaitPay = status === "NEW" || status === "BARU";

			switch (valueFilterNotif) {
				case "Semua": {
					return true;
				}
				case "Belum Dibayar": {
					return isWaitPay;
				}
				case "Transaksi Dibatalkan": {
					return isCancel;
				}
				case "Menunggu Disetujui": {
					return isWaitApprove;
				}
				case "Sudah Dibayar": {
					return isSuccess;
				}
				case "Menunggu Pembayaran": {
					return isPending;
				}
			}
		});

		setDataNotifFinance(filteredData);
	}, [valueFilterNotif, notifFinance]);

	useEffect(() => {
		let uniqueStatus = new Set();

		uniqueStatus.add("Semua");
		notifFinance?.forEach((item) => {
			const status = getStatusLabel(item.status);
			uniqueStatus.add(status);
		});

		let uniqueStatusArray = Array.from(uniqueStatus);

		setFilterNotifFinance(uniqueStatusArray as StatusCustom[]);
	}, [notifFinance]);

	return (
		<ListClass
			searchResults={searchResults}
			isNotif={isNotif}
			searchTerm={searchTerm}
			setSearchTerm={setSearchTerm}
			valueFilterNotif={valueFilterNotif}
			setValueFilterNotif={setValueFilterNotif}
			filterNotifFinance={filterNotifFinance}
		/>
	);
};

const ListClass = ({
	searchResults,
	searchTerm,
	setSearchTerm,
	valueFilterNotif,
	setValueFilterNotif,
	filterNotifFinance,
	isNotif,
}: {
	valueFilterNotif: StatusCustom;
	setValueFilterNotif: React.Dispatch<StatusCustom>;
	filterNotifFinance: StatusCustom[];
	searchResults: Datum[];
	searchTerm: string;
	setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
	isNotif?: boolean;
}) => {
	return (
		<div
			className={` ${isNotif ? "" : "shadow-md  px-3 sm:px-5"} flex flex-col w-full max-w-2xl mx-auto bg-white`}
		>
			{!isNotif && (
				<TitleWithSearch
					setSearch={setSearchTerm}
					search={searchTerm}
					title={"Kedi Pay"}
					redirectTo={`/dashboard`}
				/>
			)}
			<div className="w-full">
				<SelectFilter
					valueFilterNotif={valueFilterNotif}
					setValueFilterNotif={setValueFilterNotif}
					filterNotifFinance={filterNotifFinance}
				/>
				<ListHistory searchResults={searchResults} />
			</div>
		</div>
	);
};

const ListHistory = ({ searchResults }: { searchResults: Datum[] }) => {
	const { height, isLessThanMedium, isMobile } = useViewport();

	const styleDiv = {
		height: styleDashboard(height, isLessThanMedium, isMobile) + 15,
		overflow: "auto",
		paddingRight: "5px",
	};

	return (
		<div style={styleDiv}>
			{searchResults?.length > 0 ? (
				searchResults?.map((d, index) => {
					const status = getStatusLabel(d.status);
					const textStatus =
						d?.status === "SETTLEMENT"
							? "text-[#1DA26A]"
							: d?.status === "CANCEL" ||
									d?.status === "EXPIRE" ||
									d?.status === "EXPIRED" ||
									d?.status === "ERROR"
								? "text-red-500"
								: d?.status === "PENDING"
									? "text-yellow-500"
									: d?.status === "awaiting-approval"
										? "text-yellow-600"
										: d?.status === "NEW" || d?.status === "BARU"
											? "text-amber-600"
											: "text-gray-700";

					const isType =
						d?.notif_type === "payment"
							? "Total Bayar : "
							: d?.notif_type === "payout"
								? "Total Penarikan : "
								: "";
					return (
						<div
							key={index}
							className="text-sm justify-between py-2 px-4 border-2 rounded-lg mb-1.5 hover:bg-purple-100"
						>
							<Link
								reloadDocument
								to={
									d?.invoiceIdentifier
										? "/payment/" + d?.invoiceIdentifier
										: "#"
								}
							>
								<div className="flex items-center justify-between space-x-4 mb-2">
									<div className="flex-none px-1  text-stone-600 text-xs">
										{formatTimeAndDate(d?.createdAt).date}
									</div>
									<div className={` ${textStatus} font-bold  text-sm`}>
										{status}
									</div>
								</div>
								<div className="flex items-center space-x-4">
									<div className="flex-none relative w-14 h-14">
										<img
											width={60}
											height={60}
											src={`/static/images/logokedi.svg`}
											className="absolute inset-0 h-full w-full object-cover rounded-xl shadow-md border border-gray-300"
											alt=""
										/>
									</div>
									<div className="flex flex-col space-y-1">
										<span className="font-bold">
											{d?.invoiceItems?.length > 0
												? "( " + d?.invoiceItems[0]?.name + " )"
												: d?.extra_data?.class_name
													? "( " + d?.extra_data?.class_name + " )"
													: d?.invoiceIdentifier
														? "( " + d?.invoiceIdentifier + " )"
														: "Tidak diketahui"}
										</span>
										<span className="text-sm">
											{isType}
											<span className="text-[#1DA26A] font-semibold">
												{d?.totalAmount
													? formatCurrency(d?.totalAmount)
													: formatCurrency(0)}
											</span>
										</span>
									</div>
								</div>
							</Link>
						</div>
					);
				})
			) : (
				<div className="flex items-center justify-center h-full text-sm opacity-70">
					No data
				</div>
			)}
		</div>
	);
};

const SelectFilter = ({
	valueFilterNotif,
	setValueFilterNotif,
	filterNotifFinance,
}: {
	valueFilterNotif: StatusCustom;
	setValueFilterNotif: React.Dispatch<StatusCustom>;
	filterNotifFinance: StatusCustom[];
}) => {
	const { t } = useTranslation();
	return (
		<div className="flex items-center mb-2.5 mt-1.5 gap-x-3">
			<Select
				defaultValue="Semua"
				value={valueFilterNotif}
				onValueChange={setValueFilterNotif}
			>
				<SelectTrigger className="shadow-md rounded-lg">
					<SelectValue placeholder={t("class")} />
				</SelectTrigger>
				<SelectContent
					ref={(ref) =>
						ref?.addEventListener("touchend", (e) => e.preventDefault())
					}
				>
					{filterNotifFinance.length > 0 &&
						filterNotifFinance.map((status) => {
							return (
								<SelectItem key={status} value={status} className="py-2.5">
									{status}
								</SelectItem>
							);
						})}
				</SelectContent>
			</Select>
		</div>
	);
};

function LoadingStatus() {
	return (
		<div className="my-2 mx-2 p-5 border border-gray-200 rounded-lg shadow animate-pulse relative">
			<div className="flex items-center">
				<svg
					className="w-10 h-10 me-3 text-gray-200 dark:text-gray-700"
					aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg"
					fill="currentColor"
					viewBox="0 0 20 20"
				>
					<path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
				</svg>
				<div>
					<div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2" />
					<div className="w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
				</div>
			</div>
			<span className="sr-only">Loading...</span>
		</div>
	);
}

export function SkeltonList() {
	const loadingStatuses = Array.from({ length: 7 }, (_, index) => (
		<LoadingStatus key={index} />
	));

	return loadingStatuses;
}
